function Trigger(element, offset) {
    this.top = $(element).offset().top;
    this.scroll = {
        top: $(window).scrollTop(),
        bottom: $(window).scrollTop() + $(window).height()
    };
    this.data = this.scroll.top <= this.top && this.top <= (this.scroll.bottom - offset);
    this.toString = this.data == true ? 'started' : 'end';
    return this.data;
}

function Paralax(element, speed) {

    this.element = element;
    this.speed = speed;

    var init = false;

    var onScroll = function () {
        $(window).on('scroll paralaxScroll', function () {
            $(element).each(function (e) {
                var el = $(this);
                var trigger = new Trigger(this, 0);
                if (trigger.data) {
                    var chenhlech = (trigger.scroll.bottom - trigger.top) * speed;
                    el.css('transform', 'translateY(' + (chenhlech) + 'px)');
                    // console.log(chenhlech);
                }
            });

            if (!init) init = true;
        });
    };

    var offScroll = function () {
        $(window).off('paralaxScroll');
    };

    this.init = function () {
        onScroll();
        var isInit = setInterval(function () {
            // console.log('ok');
            if (init) {
                $(element).addClass('paralax-init');
                clearInterval(isInit);
            }
        }, 300);
    };

    this.destroy = function () {
        $(this.element).removeClass('paralax-init');
        offScroll();
    };
}

function AnimationContent(element) {
    this.element = element;

    var init = false;

    var textTag = 'h1,h2,span,p,strong,a,h3,h4,h5,img,li'

    var onScroll = function () {
        var timeout = 0;
        var delay = 100;
        $(window).on('scroll animationScroll', function () {
            $(element).each(function (e) {
                var el = $(this);
                var trigger = new Trigger(this, 100);
                if (trigger.data && !el.hasClass('a-run')) {
                    el.addClass('a-run');
                    el.find(textTag).each(function () {
                        var elChild = $(this);
                        timeout += delay;
                        setTimeout(function () {
                            elChild.addClass('fadeIn');
                            elChild.css('opacity', '1');
                            // elChild.fadeTo(500, 1);
                            // elChild.css('transform', 'translateY(0)');
                        }, timeout);
                    })
                }
            });
            timeout = 0;

            if (!init) init = true;
        });
        // trick active scroll
        $(window).scrollTop(1);
    };

    var offScroll = function () {
        $(window).off('animationScroll');
        $(element).each(function (e) {
            var el = $(this);
            el.find('a-run').removeClass('a-run');
            el.find(textTag).css('opacity', '')
            elChild.find('.fadeIn').removeClass('fadeIn');
        });
    };

    var titleSplit = function () {
        var el = $(element);
        el.find('h1,h2').each(function () {
            var target = $(this);
            var arrText = target.text().split(' ');
            var newText = '<span style="opacity: 0">' + arrText.join(' </span><span style="opacity: 0">') + '</span>';
            target.html(newText);
            target.css('opacity', '1')
            // console.log(newText);
        });
    }

    this.init = function () {
        // titleSplit();
        onScroll();
        var isInit = setInterval(function () {
            // console.log('ok');
            if (init) {
                $(element).addClass('animation-init');
                clearInterval(isInit);
            }
        }, 300);
    };

    this.destroy = function () {
        $(this.element).removeClass('animation-init');
        offScroll();
    };
}


$(function () {

    var paralax = new Paralax('.paralax', 1 / 3.5);
    var animationContent = new AnimationContent('.animation-content');


    if (!$('html').hasClass('touch')) {
        paralax.init();
        animationContent.init();

        $(window).on('beforeunload pagehide', function () {
            $(this).scrollTop(0, 0);
            animationContent.destroy();
        })
    }

    $(window).on('scroll', function () {
        if ($('.top-bar').offset().top > 50) {
            $('.top-bar').addClass('pin')
        } else {
            $('.top-bar').removeClass('pin')
        }
    })

    $('body').on('click', '.btn-more', function(e) {
        var section = $(e.target).parents('section')
        var height = section.outerHeight()
        $('html,body').animate({
            scrollTop: height - $('header .top-bar').outerHeight()
        }, 300)
    })
});